import axios from '@/axios';

async function getAllProgressPendaftaran() {
    let endpoint = `progress-pendaftaran?limit=all`
    let responseProgressPendaftar = await axios.get(endpoint)
    return responseProgressPendaftar
}

export default {
    getAllProgressPendaftaran
}