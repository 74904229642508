<template>
  <CCard>
    <CCardHeader><h4>Laporan Marketing Agent</h4></CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <BaseVueSelect
            nama-label-browse="Tahun Ajaran"
            :data-selected.sync="tahunAjaran"
            :array-options="arrayDataTahunAjaran"
            :disabled="false"
            :required="requiredTahunAjaran"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <div class="form-group">
            <label for="select2">Marketer</label>
            <v-select
              label="keterangan"
              :options="arrayMarketer"
              v-model="selectedMarketer"
              :clearable="false"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="requiredMarketer"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </CCol>
        <CCol sm="6">
          <div class="form-group">
            <label for="select-progress">Progress</label>
            <v-select
              id="select-progress"
              label="nama"
              :options="arrayProgress"
              v-model="selectedProgress"
              :disabled="isSemuaMarketer"
              :clearable="false"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <b-button class="mr-2" variant="primary" @click="onClickFilter">
            <b-icon icon="filter" class="mr-2" /> Filter
          </b-button>
          <b-button
            variant="primary"
            @click="onClickExport"
            :disabled="disabledExport"
          >
            <b-icon icon="printer" class="mr-2" />
            Export
          </b-button>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <hr />
          <CRow>
            <CCol sm="6">
              <CRow class="d-block">
                <CCol sm="12"
                  ><h4>
                    Alur Pendaftaran Mahasiswa yang Lulus
                    <hr />
                  </h4>
                </CCol>
                <CCol sm="12">
                  <BaseProgressStep
                    :array-label-progress="computedArrayAlurLulus"
                    :completed-length="completedLulusProgress"
                    :height="140"
                    :font-size="8"
                  />
                </CCol>
              </CRow>
              <CRow class="d-block">
                <CCol sm="12">
                  <h4>
                    Alur Pendaftaran Mahasiswa yang Gagal
                    <hr /></h4
                ></CCol>
                <CCol sm="12">
                  <BaseProgressStep
                    :array-label-progress="computedArrayAlurGagal"
                    :completed-length="completedGagalProgress"
                    :height="140"
                    :font-size="8"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="6">
              <CRow class="summary-marketing-agent">
                <CCol sm="12"
                  ><h4>{{ labelSummary }}</h4>
                  <hr />
                </CCol>
                <CCol sm="12">
                  <table>
                    <thead>
                      <th>Keterangan</th>
                      <th>Jumlah</th>
                    </thead>
                    <tbody>
                      <tr v-for="{ nama, total } in summary" :key="nama">
                        <td>Jumlah {{ nama }}</td>
                        <td class="text-center">
                          {{ total }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <hr />
          <h4>{{ labelHeaderLaporan }}</h4>
        </CCol>
      </CRow>
      <CRow v-if="isSemuaMarketer">
        <CCol sm="12">
          <PreviewKeseluruhanMarketingAgent
            :req-table-done="reqTableDone"
            :details-semua-marketer="detailsSemuaMarketer"
            :filters-semua-marketer="filtersSemuaMarketer"
            :on-click-progress="onClickProgress"
          />
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol sm="12">
          <PreviewSatuMarketingAgent
            :req-table-done="reqTableDone"
            :details-satu-marketer="detailsSatuMarketer"
            :filters-satu-marketer="filtersSatuMarketer"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import BaseVueSelect from "@/components/base/BaseVueSelect.vue";
import BaseProgressStep from "@/components/base/BaseProgressStep.vue";
import PreviewSatuMarketingAgent from "@/components/laporan/PreviewSatuMarketingAgent.vue";
import PreviewKeseluruhanMarketingAgent from "@/components/laporan/PreviewKeseluruhanMarketingAgent.vue";

import MarketerApi from "@/api/MarketerApi.js";
import KonfigurasiApi from "@/api/KonfigurasiApi.js";
import ProgressPendaftaranApi from "@/api/ProgressPendaftaranApi.js";

export default {
  name: "LaporanMarketingAgent",
  components: {
    BaseVueSelect,
    BaseProgressStep,
    PreviewSatuMarketingAgent,
    PreviewKeseluruhanMarketingAgent,
  },

  data() {
    return {
      arrayDataTahunAjaran: [],
      tahunAjaran: "",
      lastSelectedTahunAjaran: "",
      lastSelectedProgress: {},
      labelHeaderLaporan: "Daftar Keseluruhan Marketing Agent",
      labelSummary: "Summary Keseluruhan Marketing Agent",
      isSemuaMarketer: true,
      completedLulusProgress: 1,
      completedGagalProgress: 1,

      selectedMarketer: { id: 0, nama: "Semua", keterangan: "Semua" },
      selectedProgress: { id: 0, nama: "Semua" },
      arrayMarketer: [],
      arrayProgress: [],

      detailsSemuaMarketer: [],
      detailsSatuMarketer: [],
      summary: [],

      filtersSemuaMarketer: {
        detailsSemuaMarketer: {
          value: "",
          custom: this.functionFilterTableKeseluruhan,
        },
      },
      filtersSatuMarketer: {
        detailsSatuMarketer: {
          value: "",
          custom: this.functionFilterTableSingle,
        },
      },
      reqTableDone: true,
    };
  },
  computed: {
    disabledExport: function () {
      return !(
        this.detailsSemuaMarketer.length > 0 ||
        this.detailsSatuMarketer.length > 0
      );
    },
    requiredTahunAjaran: function () {
      if (
        this.required &&
        this.tahunAjaran != "" &&
        this.tahunAjaran.length == 9
      ) {
        let tahunAwal = this.tahunAjaran.substring(0, 4);
        let tahunAkhir = this.tahunAjaran.substring(5, 9);

        if (tahunAkhir - 1 == tahunAwal) {
          return true;
        }
        return false;
      }
      return false;
    },
    requiredMarketer: function () {
      return this.selectedMarketer.nama;
    },
    computedArrayAlurLulus: function () {
      return this.arrayProgress
        .filter(({ nama }) => !(nama == "Semua" || nama == "Gagal Seleksi"))
        .map((it) => it.nama);
    },
    computedArrayAlurGagal: function () {
      return this.arrayProgress
        .filter(
          ({ nama }) =>
            !(
              nama == "Semua" ||
              nama == "Lulus Seleksi" ||
              nama == "Belum Bayar DU" ||
              nama == "Sudah Bayar DU"
            )
        )
        .map((it) => it.nama);
    },
  },
  watch: {
    selectedMarketer: {
      handler: function (newVal) {
        this.isSemuaMarketer = newVal.nama == "Semua";
        if (this.isSemuaMarketer) {
          this.selectedProgress.id = 0;
          this.selectedProgress.nama = "Semua";
        }
      },
    },
  },
  async created() {
    this.$store.dispatch("dataComponent/set", ["nowIdMenuClicked", 2]);
    this.$_generalMixin_setIsLoading(true);
    let routeQuery = this.$route.query;
    try {
      await this.getDataTahunAjaran();
      await this.getAllProgressPendaftaran();
      await this.getAllMarketer();
      if (!routeQuery.tahunAjaran) {
        this.setDefaultTahunAjaran();
      } else {
        this.lastSelectedTahunAjaran = routeQuery.tahunAjaran;
        this.tahunAjaran = routeQuery.tahunAjaran;

        this.isSemuaMarketer = routeQuery.isSemuaMarketer == "true";

        this.lastSelectedProgress.id = routeQuery.idProgress;
        this.lastSelectedProgress.nama = routeQuery.namaProgress;

        this.selectedProgress.id = routeQuery.idProgress;
        this.selectedProgress.nama = routeQuery.namaProgress;

        this.selectedMarketer.id = routeQuery.idMarketer;
        this.selectedMarketer.nama = routeQuery.namaMarketer;
        this.selectedMarketer.keterangan = routeQuery.keteranganMarketer;

        await this.onClickFilter();
      }
    } catch (err) {
      console.log("Gagal Created component Laporan Marketing Agent");
    }
    this.$_generalMixin_setIsLoading(false);
  },
  mounted() {
    setInterval(this.incrementLength, 3000);
  },
  methods: {
    functionFilterTableKeseluruhan: function (filterValue, row) {
      filterValue = filterValue.toLowerCase();
      const nama = row.nama ? row.nama : "";
      const no_hp = row.no_hp ? row.no_hp : "";
      const email = row.email ? row.email : "";
      return (
        nama.toLowerCase().includes(filterValue) ||
        no_hp.toLowerCase().includes(filterValue) ||
        email.toLowerCase().includes(filterValue)
      );
    },
    functionFilterTableSingle: function (filterValue, row) {
      filterValue = filterValue.toLowerCase();
      const nim = row.nim ? row.nim : "-";
      const no_spmb = row.no_spmb ? row.no_spmb : "-";
      const nama = row.nama ? row.nama : "";
      const no_hp = row.no_hp ? row.no_hp : "";
      const email = row.email ? row.email : "";
      return (
        nim.toLowerCase().includes(filterValue) ||
        no_spmb.toLowerCase().includes(filterValue) ||
        nama.toLowerCase().includes(filterValue) ||
        no_hp.toLowerCase().includes(filterValue) ||
        email.toLowerCase().includes(filterValue)
      );
    },
    setDefaultTahunAjaran: function () {
      let jlhDataTahunAjaran = this.arrayDataTahunAjaran.length;
      if (jlhDataTahunAjaran > 0) {
        this.tahunAjaran = this.arrayDataTahunAjaran[jlhDataTahunAjaran - 1];
      }
    },
    getAllMarketer: async function () {
      this.arrayMarketer = [];
      try {
        let responseAllMarketer = await MarketerApi.getAllMarketer();
        if (responseAllMarketer.status == 200) {
          let dataMarketer = responseAllMarketer.data.data;
          let temp = dataMarketer.map(({ id, email, nama }) => {
            return {
              id,
              nama,
              keterangan: `${nama} (${email})`,
            };
          });
          this.arrayMarketer = [
            { id: 0, nama: "Semua", keterangan: "Semua" },
            ...temp,
          ];
        } else {
          let errorMessage = responseAllMarketer.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        return Promise.reject(err);
        // this.flash(err, "error");
      }
    },
    getDataTahunAjaran: async function () {
      try {
        let jenjang = 1;
        let resTahunAjaran = await KonfigurasiApi.getAllKonfigurasiTahunAjaranByJenjang(
          jenjang
        );
        if (resTahunAjaran.status == 200) {
          let dataTahunAjaran = resTahunAjaran.data.data;
          let temp = dataTahunAjaran.map((it) => {
            return it.tahun_ajaran;
          });
          this.arrayDataTahunAjaran = [...temp];
        } else {
          let errorMessage = resTahunAjaran.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        return Promise.reject(err);
        // this.flash(err, "error");
      }
    },
    getAllProgressPendaftaran: async function () {
      try {
        let responseProgressPendaftaran = await ProgressPendaftaranApi.getAllProgressPendaftaran();
        if (responseProgressPendaftaran.status == 200) {
          let dataProgressPendaftaran = responseProgressPendaftaran.data.data;
          let temp = dataProgressPendaftaran.map((it) => {
            return {
              id: it.id,
              nama: it.keterangan,
            };
          });
          this.arrayProgress = [{ id: 0, nama: "Semua" }, ...temp];
          temp = temp.map(({ id, nama }) => {
            return { id, nama, total: 0 };
          });
          this.summary = [...temp, { id: 0, nama: "Semua", total: 0 }];
        } else {
          let errorMessage = responseProgressPendaftaran.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        return Promise.reject(err);
        // this.flash(err, "error");
      }
    },
    incrementLength: function () {
      this.completedLulusProgress =
        (this.completedLulusProgress % this.computedArrayAlurLulus.length) + 1;
      this.completedGagalProgress =
        (this.completedGagalProgress % this.computedArrayAlurGagal.length) + 1;
    },
    doFillSummaryKeseluruhanMarketer: async function () {
      try {
        let responseSummary = await MarketerApi.getSummaryKeseluruhanMarketer(
          this.lastSelectedTahunAjaran
        );
        if (responseSummary.status == 200) {
          let dataSummary = responseSummary.data.data;
          let totalSemua = 0;
          // isi summary
          let temp = dataSummary.map(({ id, keterangan, total }) => {
            totalSemua += total;
            return {
              id,
              nama: keterangan,
              total,
            };
          });
          this.summary = [...temp, { id: 0, nama: "Semua", total: totalSemua }];
        } else {
          let errorMessage = responseSummary.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
    },
    doFillSummarySingleMarketer: async function () {
      try {
        let responseSummary = await MarketerApi.getSummarySingleMarketer(
          this.selectedMarketer.id,
          this.lastSelectedTahunAjaran,
          this.lastSelectedProgress.id
        );
        if (responseSummary.status == 200) {
          let dataSummary = responseSummary.data.data;
          let totalSemua = 0;
          // isi summary
          let temp = dataSummary.map(({ id, keterangan, total }) => {
            totalSemua += total;
            return {
              id,
              nama: keterangan,
              total,
            };
          });
          this.summary = [...temp, { id: 0, nama: "Semua", total: totalSemua }];
        } else {
          let errorMessage = responseSummary.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
    },
    doFilterKeseluruhanMarketer: async function () {
      this.labelHeaderLaporan = "Daftar Keseluruhan Marketing Agent";
      this.labelSummary = "Summary Keseluruhan Marketing Agent";
      try {
        let responseSemuaMarketer = await MarketerApi.getLaporanKeseluruhanMarketer(
          this.lastSelectedTahunAjaran
        );
        if (responseSemuaMarketer.status == 200) {
          let dataMarketer = responseSemuaMarketer.data.data;
          let temp = dataMarketer.map(
            ({
              id,
              nama,
              hp,
              email,
              kode_referral,
              url_bitly,
              progress_pendaftaran,
            }) => {
              return {
                id,
                nama,
                no_hp: hp,
                email,
                kode_referral,
                link_bitly: url_bitly,
                progress: progress_pendaftaran,
              };
            }
          );
          this.detailsSemuaMarketer = temp;
        } else {
          let errorMessage = responseSemuaMarketer.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
    },
    doFilterSingleMarketer: async function () {
      this.labelHeaderLaporan = `Daftar Laporan Detail Marketer ${this.selectedMarketer.nama}`;
      this.labelSummary = `Summary Marketing Agent ${this.selectedMarketer.nama}`;
      try {
        let responseSingleMarketer = await MarketerApi.getCalonMahasiswaSingleMarketer(
          this.selectedMarketer.id,
          this.lastSelectedTahunAjaran,
          this.lastSelectedProgress.id
        );

        if (responseSingleMarketer.status == 200) {
          let dataCalonMahasiswa = responseSingleMarketer.data.data;
          let temp = dataCalonMahasiswa.map(
            ({ id, nim, no_spmb, nama, hp, email, progress_pendaftaran }) => {
              return {
                id,
                nim,
                no_spmb,
                nama,
                no_hp: hp,
                email,
                progress: progress_pendaftaran,
              };
            }
          );
          this.detailsSatuMarketer = temp;
        } else {
          let errorMessage = responseSingleMarketer.data.error.message;
          throw errorMessage;
        }
      } catch (err) {
        this.flash(err, "error");
      }
    },
    onClickProgress: async function (marketer, progress) {
      let [localSelectedProgress, localSelectedMarketer, localTahunAjaran] = [
        { id: 0, nama: "Semua" },
        { id: 0, nama: "Semua", keterangan: "Semua" },
        "",
      ];
      if (progress != "Total") {
        let progressFounded = this.arrayProgress.find(
          (it) => it.nama == progress
        );

        localSelectedProgress.id = progressFounded.id;
        localSelectedProgress.nama = progressFounded.nama;
      }
      let marketerFounded = this.arrayMarketer.find(
        (it) => it.nama == marketer
      );
      localSelectedMarketer.id = marketerFounded.id;
      localSelectedMarketer.nama = marketerFounded.nama;
      localSelectedMarketer.keterangan = marketerFounded.keterangan;

      localTahunAjaran = this.lastSelectedTahunAjaran;

      let routeData = this.$router.resolve({
        name: "LaporanMarketingAgent",
        query: {
          idMarketer: localSelectedMarketer.id,
          namaMarketer: localSelectedMarketer.nama,
          keteranganMarketer: localSelectedMarketer.keterangan,
          idProgress: localSelectedProgress.id,
          namaProgress: localSelectedProgress.nama,
          tahunAjaran: localTahunAjaran,
          isSemuaMarketer: false,
        },
      });
      window.open(routeData.href, "_blank");
      // await this.onClickFilter();
    },
    onClickFilter: async function () {
      this.lastSelectedTahunAjaran = this.tahunAjaran;
      this.lastSelectedProgress = this.selectedProgress;

      this.reqTableDone = false;
      this.summary = [];
      this.detailsSatuMarketer = [];
      this.detailsSemuaMarketer = [];
      this.$_generalMixin_setIsLoading(true);
      if (this.isSemuaMarketer) {
        await this.doFilterKeseluruhanMarketer();
        await this.doFillSummaryKeseluruhanMarketer();
      } else {
        await this.doFilterSingleMarketer();
        await this.doFillSummarySingleMarketer();
      }
      this.reqTableDone = true;
      this.$_generalMixin_setIsLoading(false);
    },
    onClickExport: async function () {
      this.$_generalMixin_setIsLoading(true);
      let a = document.createElement("a");
      if (this.isSemuaMarketer) {
        a.href = MarketerApi.getExportLaporanKeseluruhanMarketer(
          this.lastSelectedTahunAjaran
        );
      } else {
        a.href = MarketerApi.getExportLaporanSingleMarketer(
          this.selectedMarketer.id,
          this.lastSelectedTahunAjaran,
          this.lastSelectedProgress.id
        );
      }
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      this.$_generalMixin_setIsLoading(false);
    },
  },
};
</script>
<style lang="scss" >
.summary-marketing-agent {
  table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
  }

  table th {
    font-weight: bold;
    color: white;
    background-color: blue;
  }

  table th,
  table td {
    padding: 0.5rem 1rem;
    border: 2px solid #808080;
    word-break: break-word;
    white-space: pre;
    font-size: 10pt;
  }

  tr:nth-child(even) {
    background: #cfe4ff;
  }
  tr:nth-child(odd) {
    background: #ffffff;
  }
}
</style>