<template>
  <div :style="styleHeight">
    <div class="container">
      <ul class="progressbar">
        <li
          :style="styleFontSize"
          :class="classProgressStep(i)"
          v-for="i in arrayLabelProgress.length"
          :key="i"
        >
          {{ arrayLabelProgress[i - 1] }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
/**
 * Component digunakan untuk menampilkan step-step setiap progres.
 * @example @/docs/ExampleBaseProgressStep.md
 */
export default {
  name: "BaseProgressStep",
  props: {
    /**
     * Label Setiap Progres
     * @type {Array<string>}
     */
    arrayLabelProgress: {
      type: Array,
      default: () => [],
    },
    /**
     * Font Size setiap Label
     *
     */
    fontSize: {
      type: Number,
      default: 9,
    },
    height: {
      type: Number,
      default: 100,
    },
    completedLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    styleHeight: function () {
      return `height: ${this.height}px;`;
    },
    styleFontSize: function () {
      return `font-size: ${this.fontSize}pt;`;
    },
  },
  methods: {
    classProgressStep: function (index) {
      if (index > this.completedLength) {
        return "";
      }
      return "active";
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
.container {
  width: 100%;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
}
.progressbar {
  counter-reset: step;
  li {
    float: left;
    width: 14.28%;
    position: relative;
    text-align: center;
  }
  li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
  }
  li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background: #979797;
    top: 13px;
    left: -50%;
    z-index: -1;
  }
  li.active:before {
    border-color: #3aac5d;
    background: #3aac5d;
    color: white;
  }
  li.active:after {
    background: #3aac5d;
  }
  li.active li:after {
    background: #3aac5d;
  }
  li.active li:before {
    border-color: #3aac5d;
    background: #3aac5d;
    color: white;
  }
  li:first-child:after {
    content: none;
  }
}
</style>