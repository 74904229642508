<template>
  <div class="preview-satu-marketer">
    <div v-if="!reqTableDone">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else>
      <label>Search (NIM, No SPMB, Nama, Handphone, Email) :</label>
      <input
        class="form-control"
        v-model="filtersSatuMarketer.detailsSatuMarketer.value"
        :disabled="detailsSatuMarketer.length == 0"
      />
      <br />
      <v-table
        ref="table-mhs"
        class="table-hover"
        :data="detailsSatuMarketer"
        :currentPage.sync="currentPage"
        :pageSize="10"
        :filters="filtersSatuMarketer"
        selectedClass="table-info"
        @totalPagesChanged="totalPages = $event"
      >
        <thead slot="head">
          <v-th
            v-for="it in headersSatuMarketer"
            :key="it.key"
            :sortKey="it.key"
            >{{ it.value }}</v-th
          >
          <th>Progress</th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <template v-if="detailsSatuMarketer.length > 0">
            <v-tr v-for="row in displayData" :key="row.id" :row="row">
              <td
                v-for="item in headersSatuMarketer"
                :key="item.key + ' X ' + row[item.key]"
              >
                {{ row[item.key] ? row[item.key] : "-" }}
              </td>
              <td>
                <h5>
                  <b-badge
                    pill
                    :variant="progressBadgeVariant(row['progress'])"
                    >{{ row["progress"].keterangan }}</b-badge
                  >
                </h5>
              </td>
            </v-tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="headersSatuMarketer.length + 1">Tidak ada Data</td>
            </tr>
          </template>
        </tbody>
      </v-table>
      <br />
      <smart-pagination
        :maxPageLinks="5"
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :boundaryLinks="true"
      />
    </div>
  </div>
</template>
<script>
/**
 * Component yang digunakan untuk Preview Satu Marketing Agent .
 * @example [none]
 */
export default {
  name: "PreviewSatuMarketingAgent",
  props: {
    /**
     * Flag yang menandakan request data table selesai
     */
    reqTableDone: {
      type: Boolean,
      required: true,
    },
    /**
     * Data untuk setiap baris pada table
     * @type {Array<Object>}
     */
    detailsSatuMarketer: {
      type: Array,
      required: true,
    },
    /**
     * Object yang digunakan untuk melakukan filter terhadap table
     */
    filtersSatuMarketer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      headersSatuMarketer: [
        { key: "nim", value: "NIM" },
        { key: "no_spmb", value: "No SPMB" },
        { key: "nama", value: "Nama Calon Mahasiswa" },
        { key: "no_hp", value: "Handphone" },
        { key: "email", value: "Email" },
      ],
    };
  },
  methods: {
    /**
     * Function untuk variant pada badge
     * @public
     * @event progressBadgeVariant
     * @param {Object} progress - progress
     */
    progressBadgeVariant: function (progress) {
      const boolPrimary =
        progress.keterangan == "Gagal Seleksi" ||
        progress.keterangan == "Lulus Seleksi" ||
        progress.keterangan == "Menunggu Seleksi";
      const boolWarning =
        progress.keterangan == "Belum Bayar Registrasi" ||
        progress.keterangan == "Belum Final Dokumen Seleksi";

      if (boolPrimary) {
        return "primary";
      } else if (boolWarning) {
        return "warning";
      }
      return "success";
    },
  },
};
</script>
<style lang="scss">
.preview-satu-marketer {
  table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    font-size: 11px;
  }

  table th {
    font-weight: bold;
  }

  table th,
  table td {
    padding: 0.5rem 1rem;
    border: 1px solid #e9ebec;
    word-break: break-word;
    white-space: pre;
  }

  table tr.section {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
</style>