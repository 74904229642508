<template>
  <div class="preview-keseluruhan-marketer">
    <div v-if="!reqTableDone">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-else>
      <label>Search (Nama, Handphone, Email) :</label>
      <input
        class="form-control"
        v-model="filtersSemuaMarketer.detailsSemuaMarketer.value"
        :disabled="detailsSemuaMarketer.length == 0"
      />
      <br />
      <v-table
        ref="table-mhs"
        class="table-hover"
        :data="detailsSemuaMarketer"
        :currentPage.sync="currentPage"
        :pageSize="10"
        :filters="filtersSemuaMarketer"
        selectedClass="table-info"
        @totalPagesChanged="totalPages = $event"
      >
        <thead slot="head">
          <v-th
            v-for="it in headersSemuaMarketer"
            :key="it.key"
            :sortKey="it.key"
            >{{ it.value }}</v-th
          >
          <th>Progress</th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <template v-if="detailsSemuaMarketer.length > 0">
            <v-tr v-for="row in displayData" :key="row.id" :row="row">
              <td
                v-for="item in headersSemuaMarketer"
                :key="item.key + ' X ' + row[item.key]"
              >
                {{ row[item.key] }}
              </td>
              <td>
                <CRow>
                  <b-button
                    size="sm"
                    class="mb-2 ml-2"
                    v-for="{ id, keterangan, total } in row['progress']"
                    :key="id"
                    :variant="variantButton(id)"
                    @click="onClickProgress(row['nama'], keterangan)"
                    >{{ keterangan }}
                    <b-badge variant="light">
                      {{ total }}
                    </b-badge>
                  </b-button>
                </CRow>
              </td>
            </v-tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="headersSemuaMarketer.length + 1">Tidak ada Data</td>
            </tr>
          </template>
        </tbody>
      </v-table>
      <br />
      <smart-pagination
        :maxPageLinks="5"
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :boundaryLinks="true"
      />
    </div>
  </div>
</template>
<script>
/**
 * Component yang digunakan untuk Preview Keseluruhan Marketing Agent .
 * @example [none]
 */
export default {
  name: "PreviewKeseluruhanMarketingAgent",
  props: {
    /**
     * Flag yang menandakan request data table selesai
     */
    reqTableDone: {
      type: Boolean,
      required: true,
    },
    /**
     * Data untuk setiap baris pada table
     * @type {Array<Object>}
     */
    detailsSemuaMarketer: {
      type: Array,
      required: true,
    },
    /**
     * Object yang digunakan untuk melakukan filter terhadap table
     */
    filtersSemuaMarketer: {
      type: Object,
      required: true,
    },
    /**
     * Function yang dijalankan ketika button pada table di tekan
     */
    onClickProgress: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      headersSemuaMarketer: [
        { key: "nama", value: "Nama Marketer" },
        { key: "email", value: "Email" },
        { key: "no_hp", value: "No Handphone" },
        { key: "kode_referral", value: "Kode Referal" },
        { key: "link_bitly", value: "Link Bitly" },
      ],
    };
  },
  methods: {
    variantButton: function (id) {
      if (id > 0 && id <= 2) {
        return "warning";
      }
      if (id >= 3 && id <= 5) {
        return "primary";
      }
      return "success";
    },
    // /**
    //  * Function untuk convert string ke proper case
    //  * @public
    //  * @event convertToProperCase
    //  * @param {string} labelText - Selected labelText
    //  */
    // convertToProperCase: function (labelText) {
    //   labelText = labelText.replaceAll("_", " ");
    //   let temp = labelText.split(" ");
    //   let result = "";
    //   temp.forEach((it) => {
    //     result += it.charAt(0).toUpperCase() + it.substr(1).toLowerCase() + " ";
    //   });
    //   return result.trim();
    // },
  },
};
</script>
<style lang="scss">
.preview-keseluruhan-marketer {
  table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    font-size: 11px;
  }

  table th {
    font-weight: bold;
  }

  table th,
  table td {
    padding: 0.5rem 1rem;
    border: 1px solid #e9ebec;
    word-break: break-word;
    white-space: pre;
  }

  table tr.section {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
</style>