var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_c('h4',[_vm._v("Laporan Marketing Agent")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('BaseVueSelect',{attrs:{"nama-label-browse":"Tahun Ajaran","data-selected":_vm.tahunAjaran,"array-options":_vm.arrayDataTahunAjaran,"disabled":false,"required":_vm.requiredTahunAjaran},on:{"update:dataSelected":function($event){_vm.tahunAjaran=$event},"update:data-selected":function($event){_vm.tahunAjaran=$event}}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"select2"}},[_vm._v("Marketer")]),_c('v-select',{attrs:{"label":"keterangan","options":_vm.arrayMarketer,"clearable":false},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":_vm.requiredMarketer}},'input',attributes,false),events))]}}]),model:{value:(_vm.selectedMarketer),callback:function ($$v) {_vm.selectedMarketer=$$v},expression:"selectedMarketer"}})],1)]),_c('CCol',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"select-progress"}},[_vm._v("Progress")]),_c('v-select',{attrs:{"id":"select-progress","label":"nama","options":_vm.arrayProgress,"disabled":_vm.isSemuaMarketer,"clearable":false},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.selectedProgress),callback:function ($$v) {_vm.selectedProgress=$$v},expression:"selectedProgress"}})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.onClickFilter}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"filter"}}),_vm._v(" Filter ")],1),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.disabledExport},on:{"click":_vm.onClickExport}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"printer"}}),_vm._v(" Export ")],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('hr'),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CRow',{staticClass:"d-block"},[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',[_vm._v(" Alur Pendaftaran Mahasiswa yang Lulus "),_c('hr')])]),_c('CCol',{attrs:{"sm":"12"}},[_c('BaseProgressStep',{attrs:{"array-label-progress":_vm.computedArrayAlurLulus,"completed-length":_vm.completedLulusProgress,"height":140,"font-size":8}})],1)],1),_c('CRow',{staticClass:"d-block"},[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',[_vm._v(" Alur Pendaftaran Mahasiswa yang Gagal "),_c('hr')])]),_c('CCol',{attrs:{"sm":"12"}},[_c('BaseProgressStep',{attrs:{"array-label-progress":_vm.computedArrayAlurGagal,"completed-length":_vm.completedGagalProgress,"height":140,"font-size":8}})],1)],1)],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CRow',{staticClass:"summary-marketing-agent"},[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.labelSummary))]),_c('hr')]),_c('CCol',{attrs:{"sm":"12"}},[_c('table',[_c('thead',[_c('th',[_vm._v("Keterangan")]),_c('th',[_vm._v("Jumlah")])]),_c('tbody',_vm._l((_vm.summary),function(ref){
var nama = ref.nama;
var total = ref.total;
return _c('tr',{key:nama},[_c('td',[_vm._v("Jumlah "+_vm._s(nama))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(total)+" ")])])}),0)])])],1)],1)],1),_c('hr'),_c('h4',[_vm._v(_vm._s(_vm.labelHeaderLaporan))])],1)],1),(_vm.isSemuaMarketer)?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('PreviewKeseluruhanMarketingAgent',{attrs:{"req-table-done":_vm.reqTableDone,"details-semua-marketer":_vm.detailsSemuaMarketer,"filters-semua-marketer":_vm.filtersSemuaMarketer,"on-click-progress":_vm.onClickProgress}})],1)],1):_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('PreviewSatuMarketingAgent',{attrs:{"req-table-done":_vm.reqTableDone,"details-satu-marketer":_vm.detailsSatuMarketer,"filters-satu-marketer":_vm.filtersSatuMarketer}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }