import axios from '@/axios';
import store from '@/store/store.js'

const prefix = `${process.env.VUE_APP_URL_API}/v1`;

// Fitur CRUD Marketing Agent
async function getAllMarketer() {
    let endpoint = `marketer?limit=all`
    let responseMarketer = await axios.get(endpoint)
    return responseMarketer
}

async function getAllMarketerByStatus(isActive) {
    let endpoint = `marketer?is_active=${isActive}&limit=all`
    let responseMarketer = await axios.get(endpoint)
    return responseMarketer
}

async function insertMarketer(formData) {
    let endpoint = `marketer`;
    let responseInsert = await axios.post(endpoint, formData);
    return responseInsert;
}

// params rawInput {nama, hp, email, is_active}
async function updateMarketerById(idMarketer, rawInput) {
    let endpoint = `marketer/${idMarketer}`
    let responseUpdate = await axios.patch(endpoint, rawInput);
    return responseUpdate;
}

async function refreshLinkBitly(idMarketer) {
    let endpoint = `marketer/${idMarketer}/bitlink`
    let responseRefresh = await axios.post(endpoint);
    return responseRefresh;
}

// params rawInput { path }
async function updateLinkBitly(idMarketer, rawInput) {
    let endpoint = `marketer/${idMarketer}/bitlink`
    let responseRefresh = await axios.patch(endpoint, rawInput);
    return responseRefresh;
}

function getTemplateImportMarketingAgent() {
    let token = store.getters["auth/getToken"]
    let endpoint = `${prefix}/marketer/import-template?export=1&token=${token}`
    return endpoint
}

async function importMarketer(formData) {
    let endpoint = `marketer/import`;
    let resImport = await axios.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
    return resImport;
}

function getExportMarketerByStatus(statusAktif) {
    let token = store.getters["auth/getToken"]
    let endpoint = `${prefix}/marketer?is_active=${statusAktif ? 1 :0}&token=${token}&export=1&limit=all`
    return endpoint
}

// Fitur laporan Marketing Agent
// get keseluruhan marketer 
async function getLaporanKeseluruhanMarketer(tahunAjaran) {
    let endpoint = `marketer/progress-pendaftaran?tahun_ajaran=${tahunAjaran}&limit=all`
    let responseMarketer = await axios.get(endpoint);
    return responseMarketer;
}

// get summary keseluruahn marketer 
async function getSummaryKeseluruhanMarketer(tahunAjaran) {
    let endpoint = `marketer/group/progress-pendaftaran?tahun_ajaran=${tahunAjaran}`
    let responseSummary = await axios.get(endpoint);
    return responseSummary;
}

function getExportLaporanKeseluruhanMarketer(tahunAjaran) {
    let token = store.getters["auth/getToken"]
    let endpoint = `${prefix}/marketer/progress-pendaftaran?tahun_ajaran=${tahunAjaran}&token=${token}&export=1&limit=all`
    return endpoint
}

// get calon mahasiswa single marketer 
async function getCalonMahasiswaSingleMarketer(idMarketer, tahunAjaran, idProgressPendaftaran) {
    let endpoint = `marketer/${idMarketer}/progress-pendaftaran?tahun_ajaran=${tahunAjaran}&limit=all`
    if (idProgressPendaftaran) {
        endpoint += `&id_progress_pendaftaran=${idProgressPendaftaran}`
    }
    endpoint += '&limit=all'
    let responseMarketer = await axios.get(endpoint);
    return responseMarketer;
}

// get summary satu marketer 
async function getSummarySingleMarketer(idMarketer, tahunAjaran, idProgressPendaftaran) {
    let endpoint = `marketer/${idMarketer}/group/progress-pendaftaran?tahun_ajaran=${tahunAjaran}`
    if (idProgressPendaftaran) {
        endpoint += `&id_progress_pendaftaran=${idProgressPendaftaran}`
    }
    let responseSummary = await axios.get(endpoint);
    return responseSummary;
}

function getExportLaporanSingleMarketer(idMarketer, tahunAjaran, idProgressPendaftaran) {
    let token = store.getters["auth/getToken"]
    let endpoint = `${prefix}/marketer/${idMarketer}/progress-pendaftaran?tahun_ajaran=${tahunAjaran}&token=${token}`
    if (idProgressPendaftaran) {
        endpoint += `&id_progress_pendaftaran=${idProgressPendaftaran}`
    }
    endpoint += '&export=1&limit=all'
    return endpoint
}

export default {
    getAllMarketer,
    getAllMarketerByStatus,
    insertMarketer,
    updateMarketerById,
    refreshLinkBitly,
    updateLinkBitly,
    getTemplateImportMarketingAgent,
    importMarketer,
    getExportMarketerByStatus,
    getLaporanKeseluruhanMarketer,
    getSummaryKeseluruhanMarketer,
    getExportLaporanKeseluruhanMarketer,
    getCalonMahasiswaSingleMarketer,
    getSummarySingleMarketer,
    getExportLaporanSingleMarketer,

}